import Abele from 'abele'
import { MapPin, Smartphone, Mail } from 'abele-feather'
import { graphql } from 'drinso'

// import PageHeader from '../components/PageHeader'
// import FormSimpleAjax from '../components/FormSimpleAjax'
import Content from '../components/Content'
import GoogleMap from '../components/GoogleMap'
import Layout from '../components/Layout'
import './ContactPage.css'

// Export Template for use in CMS preview
export const ContactPageTemplate = ({
  body,
  title,
  subtitle,
  featuredImage,
  address,
  address2,
  address3,
  phone,
  phone2,
  email,
  locations
}) => (
  <main className="Contact">
    {/* <PageHeader
      title={title}
      subtitle={subtitle}
      backgroundImage={featuredImage}
    /> */}
    <GoogleMap locations={locations} />
    <section className="section Contact--Section1">
      <div className="container Contact--Section1--Container">
        <div>
          <Content source={body} className="text-align-initial" />
          <div className="Contact--Details">
            {address && (
              <a
                className="Contact--Details--Item"
                href={`https://www.google.it/maps/search/${encodeURI(address)}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <MapPin /> {address}
              </a>
            )}
            {phone && (
              <a className="Contact--Details--Item" href={`tel:${phone}`}>
                <Smartphone /> {phone}
              </a>
            )}
            {phone2 && (
              <a className="Contact--Details--Item" href={`tel:${phone2}`}>
                <Smartphone /> {phone2}
              </a>
            )}
            {email && (
              <a className="Contact--Details--Item" href={`mailto:${email}`}>
                <Mail /> {email}
              </a>
            )}
          </div>
        </div>

        <div>
          {/* <FormSimpleAjax name="Simple Form Ajax" /> */}
          <img
            className="hideMobile"
            src="/images/targa_colonna.png"
            alt="SOP Logo"
            style={{ marginTop: '20px', height: '280px' }}
          />
        </div>
      </div>
    </section>

    <section className="section Contact--Section1">
      <div className="container Contact--Section1--Container">
        <div>
          <Content
            source={'# Area 12 - Officina del movimento'}
            className="text-align-initial"
          />
          <div className="Contact--Details">
            {address3 && (
              <a
                className="Contact--Details--Item"
                href={`https://www.google.it/maps/search/${encodeURI(
                  address3
                )}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <MapPin /> {address3}
              </a>
            )}
            <a className="Contact--Details--Item" href={`tel:080 52 18 289`}>
              <Smartphone /> {`080 52 18 289`}
            </a>
            <a
              className="Contact--Details--Item"
              href={`mailto:info@area12bari.com`}
            >
              <Mail /> {`info@area12bari.com`}
            </a>
          </div>
        </div>
      </div>
    </section>

    <section className="section Contact--Section1">
      <div className="container Contact--Section1--Container">
        <div>
          <Content
            source={'# International College of Osteopathic Medicine - Napoli'}
            className="text-align-initial"
          />
          <div className="Contact--Details">
            {address2 && (
              <a
                className="Contact--Details--Item"
                href={`https://www.google.it/maps/search/${encodeURI(
                  address2
                )}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <MapPin /> {address2}
              </a>
            )}
            <a className="Contact--Details--Item" href={`tel:081 41 60 97`}>
              <Smartphone /> {`081 41 60 97`}
            </a>
            <a
              className="Contact--Details--Item"
              href={`mailto:segreterianapoli@icomosteopatia.it`}
            >
              <Mail /> {`segreterianapoli@icomosteopatia.it`}
            </a>
          </div>
        </div>
      </div>
    </section>

    {/* <GoogleMap locations={locations} /> */}
  </main>
)

const ContactPage = ({ data: { page } }) => (
  <Layout
    meta={page.frontmatter.meta || false}
    title={page.frontmatter.title || false}
  >
    <ContactPageTemplate {...page.frontmatter} body={page.html} />
  </Layout>
)

export default ContactPage

export const pageQuery = graphql`
  query ContactPage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      frontmatter {
        title
        template
        subtitle
        featuredImage
        address
        address2
        address3
        phone
        phone2
        email
        locations {
          mapLink
          lat
          lng
        }
      }
    }
  }
`
