import Abele, { Component } from 'abele'
import GoogleMapAbele from 'google-map-abele'
import { MapPin } from 'abele-feather'

let mapkey = ''
if (process.env.NETLIFY_MAP_KEY) {
  mapkey = process.env.NETLIFY_MAP_KEY
}

class GoogleMap extends Component {
  static defaultProps = {
    center: {
      lat: 40.815762,
      lng: 16.421824
    },
    zoom: 8
  }

  render() {
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: '50vh', width: '100%' }}>
        <GoogleMapAbele
          bootstrapURLKeys={{ key: mapkey }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          <Marker lat={40.815762} lng={16.421824} text={'Gravina'} />
          <Marker lat={41.123375} lng={16.871849} text={'Bari'} />
          <Marker lat={40.833974} lng={14.234125} text={'Napoli'} />
        </GoogleMapAbele>
      </div>
    )
  }
}

export default GoogleMap

const Marker = () => {
  return (
    <div
      style={{
        color: 'red',
        position: 'absolute',
        transform: 'translate(-50%, -50%)'
      }}
    >
      <MapPin />
    </div>
  )
}
